import { useState, useEffect } from 'react';
import {
    Box, TextField, MenuItem,
    useTheme,
    useMediaQuery, InputAdornment,
    FormControl, InputLabel, Select
} from '@mui/material';
import ButtonComponent from '../ButtonComponent/ButtonComponent';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';

// Assets
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import PeopleIcon from '@mui/icons-material/People';
import { toast } from 'react-toastify'


const SearchBar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const locations = [
        {
            value: 'all',
            label: 'All Locations',
        },
        {
            value: 'Darjeeling',
            label: 'Darjeeling',
        },
        {
            value: 'Kalimpong',
            label: 'Kalimpong',
        },
        {
            value: 'Kurseong',
            label: 'Kurseong',
        },
        {
            value: 'Takdah',
            label: 'Takdah',
        },
        {
            value: 'Sittong',
            label: 'Sittong',
        },
        {
            value: 'Deorali',
            label: 'Deorali',
        }
    ];

    const [location, setLocation] = useState(locations[0].value);
    const [checkInDate, setCheckInDate] = useState<Dayjs | null>(dayjs());
    const [checkOutDate, setCheckOutDate] = useState<Dayjs | null>(dayjs().add(1, 'day'));
    const [guests, setGuests] = useState(1);

    // Update check-out date whenever check-in date changes
    useEffect(() => {
        if (checkInDate) {
            const newCheckOutDate = checkInDate.add(1, 'day');
            setCheckOutDate(newCheckOutDate);
        }
    }, [checkInDate]);

    // Validation logic: Check-In date should be at least +4 days from the current date
    const handleCheckInChange = (newValue: Dayjs | null) => {
        const minCheckInDate = dayjs().add(4, 'day');

        if (newValue && newValue.isBefore(minCheckInDate)) {
            toast.error('Check-in date should be at least +4 days from current date');
            setCheckInDate(minCheckInDate); // Set the date to minimum valid date
        } else {
            setCheckInDate(newValue);
        }
    };

    // Validation logic: Check-out date should be at least +1 day from check-in date
    const handleCheckOutChange = (newValue: Dayjs | null) => {
        if (newValue && checkInDate && newValue.isBefore(checkInDate.add(1, 'day'))) {
            toast.error('Check-out date should be at least +1 day from check-in date');

            // Temporarily clear date to force re-render, then set it to the minimum valid date
            setCheckOutDate(null);
            setTimeout(() => setCheckOutDate(checkInDate.add(1, 'day')), 0);
        } else {
            setCheckOutDate(newValue);
        }
    };

    const handleSearch = () => {
        // Create an object to store the search data
        const searchData = {
            location,
            checkInDate: checkInDate ? checkInDate.startOf('day').toISOString() : null,
            checkOutDate: checkOutDate ? checkOutDate.startOf('day').toISOString() : null,
            guests,
        };

        // Save the search data to session storage
        sessionStorage.setItem('searchData', JSON.stringify(searchData));

        // Redirect to the search results page
        window.location.href = '/search/homestays';
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                gap: isMobile ? 2 : 1,
                alignItems: isMobile ? 'center' : 'baseline',
                paddingX: isMobile ? 1 : 4,
                paddingBottom: 3,
                paddingTop: 2,
                borderRadius: 4,
                backgroundColor: theme.palette.background.paper,
                boxShadow: `0 30px 60px 0 rgba(27, 31, 10, 0.08)`,
                width: isMobile ? '100%' : '80vw',
                height: isMobile ? '390px' : 'auto',
                maxWidth: {
                    xs: '100%', // Mobile
                    sm: '900px', // Tablet
                    md: '1400px', // Desktop
                },
                marginBottom: isMobile ? '16px' : '0',
            }}>

            {/* Location */}
            <TextField
                id="outlined-select-location"
                select
                label="Select your preferred location"
                defaultValue={locations[0].value}
                onChange={(e) => setLocation(e.target.value as string)}
                sx={{
                    flex: isMobile ? '1 1 100%' : '1 1 0',
                    textAlign: isMobile ? 'left' : 'center',
                    width: isMobile ? '100%' : 'auto'
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <FmdGoodIcon />
                        </InputAdornment>
                    ),
                }}
            >
                {locations.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>


            {/* Number of Guests - Dropdown */}
            <FormControl fullWidth sx={{
                flex: isMobile ? '1 1 100%' : '0.5 1 0',
                textAlign: isMobile ? 'left' : 'center',
                width: isMobile ? '100%' : 'auto'
            }}>
                <InputLabel>Guests</InputLabel>
                <Select
                    label="Guests"
                    value={guests}
                    onChange={(e) => setGuests(e.target.value as number)}
                    startAdornment={
                        <InputAdornment position="start">
                            <PeopleIcon />
                        </InputAdornment>
                    }
                >
                    {Array.from({ length: 10 }, (_, i) => (
                        <MenuItem key={i + 1} value={i + 1}>
                            {i + 1}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>


            {/* Check-in Date */}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DemoContainer
                    components={['DatePicker']}
                    sx={{
                        flex: isMobile ? '2 1 100%' : '1 1 0',
                        textAlign: isMobile ? 'left' : 'center',
                        width: isMobile ? '100%' : 'auto',
                        overflow: 'initial',
                    }}
                >
                    <DatePicker
                        label="Check In Date"
                        value={checkInDate}
                        onChange={(newValue) => handleCheckInChange(newValue)}
                    />
                </DemoContainer>
            </LocalizationProvider>

            {/* Check-out Date */}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DemoContainer components={['DatePicker']} sx={{
                    flex: isMobile ? '2 1 100%' : '1 1 0',
                    textAlign: isMobile ? 'left' : 'center',
                    width: isMobile ? '100%' : 'auto',
                    overflow: 'initial'
                }}>
                    <DatePicker
                        label="Check Out Date"
                        value={checkOutDate}
                        slotProps={{ textField: { fullWidth: true } }}
                        onChange={(newValue) => handleCheckOutChange(newValue)}
                    />
                </DemoContainer>
            </LocalizationProvider>

            {/* Search Button */}
            <ButtonComponent
                label="Search"
                onClick={handleSearch}
                sx={{
                    height: '61.5px',
                    padding: '0px',
                    flex: isMobile ? '1 1 100%' : '1.5 1 0',
                    width: isMobile ? '100%' : 'auto'
                }}
            />

        </Box>
    );
};

export default SearchBar;
