import { createTheme } from "@mui/material/styles";

// Define your theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#5486E9", // Primary brand color
      dark: "#F0F0F0", // Hover Color
    },
    secondary: {
      main: "#97B5F1", // Secondary brand color
    },
    error: {
      main: "#FF5B5B", // Error color
    },
    success: {
      main: "#24CC34", // Success color
    },
    warning: {
      main: "#FFC046", // Warning color
    },
    info: {
      main: "#22D2EA", // Info color
    },
    background: {
      default: "#F4F6F8", // Background color
      paper: "#FFFFFF", // Paper color
    },
    text: {
      primary: "#272727", // Primary text color
      secondary: "#3E3E3E", // Secondary text color
      disabled: "#9E9E9E", // Disabled text color
    },
  },
  typography: {
    fontFamily: "'Figtree', sans-serif",
    fontSize: 20,
    h1: {
      fontSize: "40px",
      fontWeight: 700,
      color: "#272727",
    },
    h2: {
      fontSize: "32px",
      fontWeight: 600,
      color: "#272727",
    },
    h3: {
      fontSize: "24px",
      fontWeight: 600,
    },
    h4: {
      fontSize: "20px",
      fontWeight: 500,
      color: "#272727",
    },
    h5: {
      fontSize: "20px",
      fontWeight: 500,
      color: "#272727",
    },
    h6: {
      fontSize: "20px",
      fontWeight: 400,
      color: "#272727",
      lineHeight: "1.5",
    },
    body1: {
      fontSize: "18px",
      fontWeight: 400,
      color: "#3E3E3E",
    },
    body2: {
      fontSize: "22px",
      fontWeight: 400,
      lineHeight: "1.3",
    },
    button: {
      fontSize: "24px",
      fontWeight: 500,
      textTransform: "none",
    },
    caption: {
      fontSize: "16px",
      fontWeight: 300,
      color: "#3E3E3E",
    },
  },
  shape: {
    borderRadius: 3,
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: "90vw",
        maxWidthMd: "90vw",
        maxWidthSm: "342px",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: "12px 8px",
          transition: "all 0.3s ease",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#272727",
          textDecoration: "none",
          transition: "all 0.3s ease",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
  },
});

export default theme;
